// src/App.js
import React, { useState } from 'react';
import './App.css';

const products = [
  { id: 1, name:{englishName:'Raw Rice', tamilName:'பச்சரிசி'}},
  { id: 2, name:{englishName:'Brown Rice', tamilName:'பழுப்பு அரிசி'}},
  { id: 3, name:{englishName:'Wheat Flour (Atta)', tamilName:'கோதுமை மாவு (அட்டா)'}},
  { id: 4, name:{englishName:'Maida', tamilName:'மைதா'}},
  { id: 5, name:{englishName:'Rava', tamilName:'ரவா'}},
  { id: 6, name:{englishName:'Ragi Flour', tamilName:'ராகி மாவு'}},
  { id: 7, name:{englishName:'Rice Flour', tamilName:'அரிசி மாவு'}},
  { id: 8, name:{englishName:'Little Millet', tamilName:'சிறிய தினை'}},
  { id: 9, name:{englishName:'Pasta', tamilName:'பாஸ்தா'}},
  { id: 10, name:{englishName:'Noodles', tamilName:'நூடுல்ஸ்'}},
  { id: 11, name:{englishName:'Pressed Rice (Aval, Poha)', tamilName:'அழுத்தப்பட்ட அரிசி (அவல், போஹா)'}},
  { id: 12, name:{englishName:'Sago (Javarisi, Sabudhana)', tamilName:'சாகோ (ஜவரிசி, சபுதானா)'}},
  { id: 13, name:{englishName:'Gram Flour (Besan, Kadalai Maavu)', tamilName:'பருப்பு மாவு (பேசன், கடலை மாவு)'}},
  { id: 14, name:{englishName:'Vermicelli', tamilName:'வெர்மிசெல்லி'}},
  { id: 15, name:{englishName:'Instant Rice Sevai', tamilName:'உடனடி அரிசி சேவை'}},
  { id: 16, name:{englishName:'Broken Wheat (Samba Godhumai, Lapsi)', tamilName:'உடைந்த கோதுமை (சம்பா கோதுமை, லப்சி)'}},
  { id: 17, name:{englishName:'Urad Dal', tamilName:'உரத் தால்'}},
  { id: 18, name:{englishName:'Toor Dal', tamilName:'டூர் தால்'}},
  { id: 19, name:{englishName:'Moong Dal', tamilName:'மூங் தால்'}},
  { id: 20, name:{englishName:'Channa Dal', tamilName:'சன்னா தால்'}},
  { id: 21, name:{englishName:'Chickpeas', tamilName:'சுண்டல்'}},
  { id: 22, name:{englishName:'Dried Green Peas', tamilName:'உலர்ந்த பச்சை பட்டாணி'}},
  { id: 23, name:{englishName:'Green Gram', tamilName:'பச்சை கிராம்'}},
  { id: 24, name:{englishName:'Black Eye Beans', tamilName:'கருப்பு கண் பீன்ஸ்'}},
  { id: 25, name:{englishName:'Frozen Green peas', tamilName:'உறைந்த பச்சை பட்டாணி'}},
  { id: 26, name:{englishName:'Frozen Corn', tamilName:'உறைந்த சோளம்'}},
  { id: 27, name:{englishName:'Sunflower Oil', tamilName:'சூரியகாந்தி எண்ணெய்'}},
  { id: 28, name:{englishName:'Rice Bran Oil', tamilName:'அரிசி தவிடு எண்ணெய்'}},
  { id: 29, name:{englishName:'Sesame Oil', tamilName:'எள் எண்ணெய்'}},
  { id: 30, name:{englishName:'Coconut Oil', tamilName:'தேங்காய் எண்ணெய்'}},
  { id: 31, name:{englishName:'Olive Oil', tamilName:'ஆலிவ் எண்ணெய்'}},
  { id: 32, name:{englishName:'Ghee', tamilName:'நெய்'}},
  { id: 33, name:{englishName:'Butter', tamilName:'வெண்ணெய்'}},
  { id: 34, name:{englishName:'Salt', tamilName:'உப்பு'}},
  { id: 35, name:{englishName:'Crystal Salt', tamilName:'படிக உப்பு'}},
  { id: 36, name:{englishName:'Sugar', tamilName:'சர்க்கரை'}},
  { id: 37, name:{englishName:'Jaggery', tamilName:'வெல்லம்'}},
  { id: 38, name:{englishName:'Palm Jaggery', tamilName:'பனை வெல்லம்'}},
  { id: 39, name:{englishName:'Tea', tamilName:'தேநீர்'}},
  { id: 40, name:{englishName:'Coffee', tamilName:'கொட்டைவடி நீர்'}},
  { id: 41, name:{englishName:'Health Drinks', tamilName:'ஆரோக்கிய பானங்கள்'}},
  { id: 42, name:{englishName:'Chocolate Syrup', tamilName:'சாக்லேட் சிரப்'}},
  { id: 43, name:{englishName:'Tamarind', tamilName:'புளி'}},
  { id: 44, name:{englishName:'Asafoetida', tamilName:'அசாஃபோடிடா'}},
  { id: 45, name:{englishName:'Dry Red Chilies', tamilName:'காய்ந்த சிவப்பு மிளகாய்'}},
  { id: 46, name:{englishName:'Mustard Seeds', tamilName:'கடுகு விதைகள்'}},
  { id: 47, name:{englishName:'Cumin Seeds', tamilName:'சீரகம்'}},
  { id: 48, name:{englishName:'Fennel Seeds', tamilName:'பெருஞ்சீரகம் விதைகள்'}},
  { id: 49, name:{englishName:'Fenugreek Seeds', tamilName:'வெந்தய விதைகள்'}},
  { id: 50, name:{englishName:'Peppercorns', tamilName:'மிளகுத்தூள்'}},
  { id: 51, name:{englishName:'Poppy Seeds', tamilName:'பாப்பி விதைகள்'}},
  { id: 52, name:{englishName:'White Sesame Seeds', tamilName:'வெள்ளை எள் விதைகள்'}},
  { id: 53, name:{englishName:'Carom Seeds', tamilName:'ஓமம்'}},
  { id: 54, name:{englishName:'Dry Ginger', tamilName:'உலர் இஞ்சி'}},
  { id: 55, name:{englishName:'Cardamom', tamilName:'ஏலக்காய்'}},
  { id: 56, name:{englishName:'Cinnamom', tamilName:'இலவங்கப்பட்டை'}},
  { id: 57, name:{englishName:'Star anise', tamilName:'நட்சத்திர சோம்பு'}},
  { id: 58, name:{englishName:'Cloves', tamilName:'கிராம்பு'}},
  { id: 59, name:{englishName:'Bay Leaf', tamilName:'பிரியாணி இலை'}},
  { id: 60, name:{englishName:'Papad & Vadagams', tamilName:'பாப்பாட் & வடகங்கள்'}},
  { id: 61, name:{englishName:'Pickle', tamilName:'ஊறுகாய்'}},
  { id: 62, name:{englishName:'Ginger Garlic Paste', tamilName:'இஞ்சி பூண்டு விழுது'}},
  { id: 63, name:{englishName:'Turmeric Powder', tamilName:'மஞ்சள் தூள்'}},
  { id: 64, name:{englishName:'Sambar Powder', tamilName:'சாம்பார் பொடி'}},
  { id: 65, name:{englishName:'Chicken Masala', tamilName:'சிக்கன் மசாலா'}},
  { id: 66, name:{englishName:'Red Chilli Powder', tamilName:'சிவப்பு மிளகாய் தூள்'}},
  { id: 67, name:{englishName:'Coriander Powder', tamilName:'கொத்தமல்லி தூள்'}},
  { id: 68, name:{englishName:'Garam Masala Powder', tamilName:'கரம் மசாலா தூள்'}},
  { id: 69, name:{englishName:'Black Pepper Powders', tamilName:'கருப்பு மிளகு பொடிகள்'}},
  { id: 70, name:{englishName:'Cumin Powders', tamilName:'சீரகப் பொடிகள்'}},
  { id: 71, name:{englishName:'Idli Podi', tamilName:'இட்லி பொடி'}},
  { id: 72, name:{englishName:'Garlic Powder', tamilName:'பூண்டு தூள்'}},
  { id: 73, name:{englishName:'Channa Masala', tamilName:'சன்னா மசாலா'}},
  { id: 74, name:{englishName:'Citric Acid', tamilName:'சிட்ரிக் அமிலம்'}},
  { id: 75, name:{englishName:'Soy Sauce', tamilName:'சோயா சாஸ்'}},
  { id: 76, name:{englishName:'Tomato Ketchup', tamilName:'தக்காளி கெட்ச்அப்'}},
  { id: 77, name:{englishName:'Chilli Sauce', tamilName:'சில்லி சாஸ்'}},
  { id: 78, name:{englishName:'Vinegar', tamilName:'வினிகர்'}},
  { id: 79, name:{englishName:'Mayonnaise', tamilName:'மயோனைசே'}},
  { id: 80, name:{englishName:'Jam', tamilName:'ஜாம்'}},
  { id: 81, name:{englishName:'Cheese Spread', tamilName:'சீஸ் பரவல்'}},
  { id: 82, name:{englishName:'Cheese Slices', tamilName:'சீஸ் துண்டுகள்'}},
  { id: 83, name:{englishName:'Cheese Block', tamilName:'சீஸ் தொகுதி'}},
  { id: 84, name:{englishName:'Plain Curd', tamilName:'வெற்று தயிர்'}},
  { id: 85, name:{englishName:'Butter', tamilName:'வெண்ணெய்'}},
  { id: 86, name:{englishName:'Paneer', tamilName:'பன்னீர்'}},
  { id: 87, name:{englishName:'Fresh Cream', tamilName:'புதிய கிரீம்'}},
  { id: 88, name:{englishName:'Honey', tamilName:'தேன்'}},
  { id: 89, name:{englishName:'Cashews', tamilName:'முந்திரி'}},
  { id: 90, name:{englishName:'Raisins', tamilName:'திராட்சையும்'}},
  { id: 91, name:{englishName:'Almonds', tamilName:'பாதாம்'}},
  { id: 92, name:{englishName:'Dates', tamilName:'தேதிகள்'}},
  { id: 93, name:{englishName:'Peanuts', tamilName:'வேர்க்கடலை'}},
  { id: 94, name:{englishName:'Other nuts', tamilName:'மற்ற கொட்டைகள்'}},
  { id: 95, name:{englishName:'Other Dry Fruits', tamilName:'மற்ற உலர் பழங்கள்'}},
  { id: 96, name:{englishName:'Yeast', tamilName:'ஈஸ்ட்'}},
  { id: 97, name:{englishName:'Baking Soda (Cooking Soda)', tamilName:'பேக்கிங் சோடா (சமையல் சோடா)'}},
  { id: 98, name:{englishName:'Baking Powder', tamilName:'பேக்கிங் பவுடர்'}},
  { id: 99, name:{englishName:'Cocoa Powder', tamilName:'கொக்கோ தூள்'}},
  { id: 100, name:{englishName:'Vanilla Extract', tamilName:'வெண்ணிலா சாறை'}},
  { id: 101, name:{englishName:'Condensed Milk', tamilName:'சுண்டிய பால்'}},
  { id: 102, name:{englishName:'Cereal Boxes', tamilName:'தானிய பெட்டிகள்'}},
  { id: 103, name:{englishName:'Bread', tamilName:'ரொட்டி'}},
  { id: 104, name:{englishName:'Flavoured Yogurt', tamilName:'சுவையான தயிர்'}},
  { id: 105, name:{englishName:'Biscuits, Cakes and Cookies', tamilName:'பிஸ்கட், கேக்குகள் மற்றும் குக்கீகள்'}},
  { id: 106, name:{englishName:'Fried Packed Snacks', tamilName:'வறுத்த பேக் செய்யப்பட்ட ஸ்நாக்ஸ்'}},
  { id: 107, name:{englishName:'Popcorn Packs', tamilName:'பாப்கார்ன் பொதிகள்'}},
  { id: 108, name:{englishName:'Dish Washing Bar or Liquid', tamilName:'பாத்திரம் கழுவும் பட்டை அல்லது திரவம்'}},
  { id: 109, name:{englishName:'Washing Machine Detergent', tamilName:'சலவை இயந்திர சோப்பு'}},
  { id: 110, name:{englishName:'Bleach (for white clothes)', tamilName:'ப்ளீச் (வெள்ளை ஆடைகளுக்கு)'}},
  { id: 111, name:{englishName:'Instant Starch', tamilName:'உடனடி ஸ்டார்ச்'}},
  { id: 112, name:{englishName:'Fabric Blue', tamilName:'துணி நீலம்'}},
  { id: 113, name:{englishName:'Laundry Soap', tamilName:'சலவை சோப்பு'}},
  { id: 114, name:{englishName:'Hand Wash Liquid', tamilName:'கை கழுவும் திரவம்'}},
  { id: 115, name:{englishName:'Toilet Cleaner', tamilName:'டாய்லெட் கிளீனர்'}},
  { id: 116, name:{englishName:'Floor Cleaner', tamilName:'தரையை சுத்தம் செய்பவர்'}},
  { id: 117, name:{englishName:'Glass Cleaner', tamilName:'கண்ணாடி துப்புரவாளர்'}},
  { id: 118, name:{englishName:'All purpose cleaner (or Soap Oil)', tamilName:'அனைத்து பயன்பாட்டு துப்புரவாளர் (அல்லது சோப்பு எண்ணெய்)'}},
  { id: 119, name:{englishName:'Room Spray', tamilName:'அறை தெளிப்பு'}},
  { id: 120, name:{englishName:'Air Freshner for Bathroom', tamilName:'குளியலறைக்கு ஏர் ஃப்ரெஷ்னர்'}},
  { id: 121, name:{englishName:'Harpic Tablets', tamilName:'ஹார்பிக் மாத்திரைகள்'}},
  { id: 122, name:{englishName:'Garbage Bags', tamilName:'குப்பையிடும் பைகள்'}},
  { id: 123, name:{englishName:'Broom', tamilName:'துடைப்பம்'}},
  { id: 124, name:{englishName:'Pest Control', tamilName:'பூச்சி கட்டுப்பாடு'}},
  { id: 125, name:{englishName:'Body Soap', tamilName:'உடல் சோப்பு'}},
  { id: 126, name:{englishName:'Face Powder', tamilName:'முகத் தூள்'}},
  { id: 127, name:{englishName:'Deodorants', tamilName:'டியோடரண்டுகள்'}},
  { id: 128, name:{englishName:'Perfume', tamilName:'வாசனை'}},
  { id: 129, name:{englishName:'Shampoo', tamilName:'ஷாம்பு'}},
  { id: 130, name:{englishName:'Conditioner', tamilName:'கண்டிஷனர்'}},
  { id: 131, name:{englishName:'Moisturiser', tamilName:'ஈரப்பதம்'}},
  { id: 132, name:{englishName:'Sun Screen Lotion', tamilName:'சன் ஸ்கிரீன் லோஷன்'}},
  { id: 133, name:{englishName:'Hair Oil or Gel', tamilName:'முடி எண்ணெய் அல்லது ஜெல்'}},
  { id: 134, name:{englishName:'Toothpaste', tamilName:'பற்பசை'}},
  { id: 135, name:{englishName:'Toothbrush', tamilName:'பல் துலக்குதல்'}},
  { id: 136, name:{englishName:'Tongue Cleaner', tamilName:'நாக்கு சுத்தம் செய்பவர்'}},
  { id: 137, name:{englishName:'Shaving Cream', tamilName:'சவரக்குழைவு'}},
  { id: 138, name:{englishName:'Shaving Razors', tamilName:'ஷேவிங் ரேஸர்கள்'}},
  { id: 139, name:{englishName:'Hand Sanitizer', tamilName:'ஹேன்ட் சானிடைஷர்'}},
  { id: 140, name:{englishName:'Dettol', tamilName:'டெட்டால்'}},
  { id: 141, name:{englishName:'Other Cosmetics', tamilName:'பிற அழகுசாதனப் பொருட்கள்'}},
  { id: 142, name:{englishName:'Sanitary Napkins', tamilName:'சுகாதார நாப்கின்கள்'}},
  { id: 143, name:{englishName:'Toilet Paper Roll', tamilName:'டாய்லெட் பேப்பர் ரோல்'}},
  { id: 144, name:{englishName:'Kitchen Roll', tamilName:'சமையலறை ரோல்'}},
  { id: 145, name:{englishName:'Cotton Balls', tamilName:'பருத்தி பந்துகள்'}},
  { id: 146, name:{englishName:'Tissue Paper', tamilName:'டிஷ்யூ பேப்பர்'}},
  { id: 147, name:{englishName:'Paper Plates', tamilName:'காகித தட்டுகள்'}},
  { id: 148, name:{englishName:'Paper Cups', tamilName:'காகித கோப்பைகள்'}},
  { id: 149, name:{englishName:'Disposable Spoons', tamilName:'செலவழிப்பு கரண்டி'}},
  { id: 150, name:{englishName:'Aluminium Foil', tamilName:'அலுமினிய தகடு'}},
  { id: 151, name:{englishName:'Ziploc Bags', tamilName:'ஜிப்லாக் பைகள்'}},
  { id: 152, name:{englishName:'Toothpicks', tamilName:'டூத்பிக்ஸ்'}},
  { id: 153, name:{englishName:'Onion', tamilName:'வெங்காயம்'}},
  { id: 154, name:{englishName:'Tomato', tamilName:'தக்காளி'}},
  { id: 155, name:{englishName:'Small Onions', tamilName:'சின்ன வெங்காயம்'}},
  { id: 156, name:{englishName:'Garlic', tamilName:'பூண்டு'}},
  { id: 157, name:{englishName:'Ginger', tamilName:'இஞ்சி'}},
  { id: 158, name:{englishName:'Green Chilies', tamilName:'பச்சை மிளகாய்'}},
  { id: 159, name:{englishName:'Potatoes', tamilName:'உருளைக்கிழங்கு'}},
  { id: 160, name:{englishName:'Lemon', tamilName:'எலுமிச்சை'}},
  { id: 161, name:{englishName:'Coriander Leaves', tamilName:'கொத்துமல்லி தழை'}},
  { id: 162, name:{englishName:'Mint Leaves', tamilName:'புதினா இலைகள்'}},
  { id: 163, name:{englishName:'Curry Leaves', tamilName:'கறிவேப்பிலை'}},
  { id: 164, name:{englishName:'Coconut', tamilName:'தேங்காய்'}},
  { id: 165, name:{englishName:'Carrots', tamilName:'கேரட்'}},
  { id: 166, name:{englishName:'Other Vegetables', tamilName:'மற்ற காய்கறிகள்'}},
  { id: 167, name:{englishName:'Fruits', tamilName:'பழங்கள்'}},
  { id: 168, name:{englishName:'Batteries', tamilName:'பேட்டரிகள்'}},
  { id: 169, name:{englishName:'Light Bulbs', tamilName:'ஒளி விளக்குகள்'}},
  { id: 170, name:{englishName:'Car Cleaners', tamilName:'கார் கிளீனர்கள்'}},
  { id: 171, name:{englishName:'Foxtail Millet', tamilName:'ஃபாக்ஸ்டெயில் தினை'}},
  { id: 172, name:{englishName:'Barnyard Millet', tamilName:'Barnyard Millet'}},
  { id: 173, name:{englishName:'Kodo Millet', tamilName:'கோடோ தினை'}},
  { id: 174, name:{englishName:'Pearl Millet', tamilName:'முத்து தினை'}},
  { id: 175, name:{englishName:'Red Kidney Beans', tamilName:'சிவப்பு சிறுநீரக பீன்ஸ்'}},
  { id: 176, name:{englishName:'Whole Masoor', tamilName:'முழு மசூர்'}},
  { id: 177, name:{englishName:'Whole Urad', tamilName:'முழு உரத்'}},
  { id: 178, name:{englishName:'Double Beans', tamilName:'இரட்டை பீன்ஸ்'}},
  { id: 179, name:{englishName:'Green Peas Dal', tamilName:'பச்சை பட்டாணி பருப்பு'}},
  { id: 180, name:{englishName:'Masoor Dal', tamilName:'மசூர் தால்'}},
  { id: 181, name:{englishName:'Red Chowli', tamilName:'சிவப்பு சௌலி'}},
  { id: 182, name:{englishName:'Caramel Syrup', tamilName:'கேரமல் சிரப்'}},
  { id: 183, name:{englishName:'Strawberry Syrup', tamilName:'ஸ்ட்ராபெரி சிரப்'}},
  { id: 184, name:{englishName:'Rasam Powder', tamilName:'ரசம் பொடி'}},
  { id: 185, name:{englishName:'Vathakuzhambu Paste', tamilName:'வத்தகுழம்பு பேஸ்ட்'}},
  { id: 186, name:{englishName:'Pav Bhaji Masala', tamilName:'பாவ் பாஜி மசாலா'}},
  { id: 187, name:{englishName:'Gobi 65 Mix', tamilName:'கோபி 65 மிக்ஸ்'}},
  { id: 188, name:{englishName:'Nigella Seeds', tamilName:'நைஜெல்லா விதைகள்'}},
  { id: 189, name:{englishName:'Ajinomoto', tamilName:'அஜினோமோட்டோ'}},
  { id: 190, name:{englishName:'Dry Ginger', tamilName:'உலர் இஞ்சி'}},
  { id: 191, name:{englishName:'Black Chickpeas', tamilName:'கருப்பு கொண்டைக்கடலை'}},
  { id: 192, name:{englishName:'Vegetable Oil', tamilName:'தாவர எண்ணெய்'}},
  { id: 193, name:{englishName:'Groundnut Oil', tamilName:'நிலக்கடலை எண்ணெய்'}},
  { id: 194, name:{englishName:'Black Sesame Seeds', tamilName:'கருப்பு எள் விதைகள்'}},
  { id: 195, name:{englishName:'Flavoured Yogurt', tamilName:'சுவையான தயிர்'}},
  { id: 196, name:{englishName:'walnuts', tamilName:'அக்ரூட் பருப்புகள்'}},
  { id: 197, name:{englishName:'pista', tamilName:'பிஸ்தா'}},
  { id: 198, name:{englishName:'dried  gooseberries', tamilName:'உலர்ந்த நெல்லிக்காய்'}},
  { id: 199, name:{englishName:'figs', tamilName:'அத்திப்பழம்'}},
  { id: 200, name:{englishName:'Fabric Softener', tamilName:'துணி மென்மைப்படுத்திகளை'}},
  { id: 201, name:{englishName:'Cuff Cleaners', tamilName:'கஃப் கிளீனர்கள்'}},
  { id: 202, name:{englishName:'Mosquito Repellent', tamilName:'கொசு விரட்டி'}},
  { id: 203, name:{englishName:'Buy Spray', tamilName:'ஸ்ப்ரே வாங்கவும்'}},
  { id: 204, name:{englishName:'Lines', tamilName:'கோடுகள்'}},
  { id: 205, name:{englishName:'Balls', tamilName:'பந்துகள்'}},
  { id: 206, name:{englishName:'Mop', tamilName:'துடைப்பான்'}},
  { id: 207, name:{englishName:'Cleaning  Brushes', tamilName:'சுத்தம் செய்யும் தூரிகைகள்'}},
  { id: 208, name:{englishName:'Tooth  Brushes', tamilName:'பல் தூரிகைகள்'}},
  { id: 209, name:{englishName:'Body Lotion', tamilName:'பாடி லோஷன்'}},
  { id: 210, name:{englishName:'Ear Buds', tamilName:'இயர் பட்ஸ்'}},
  { id: 211, name:{englishName:'Red Rice', tamilName:'சிவப்பு அரிசி'}},
  { id: 212, name:{englishName:'Black Rice', tamilName:'கருப்பு அரிசி'}},
  ]


const PRODUCTS_PER_PAGE = 30;

const App = () => {
  const [checkedItems, setCheckedItems] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [language, setLanguage] = useState('englishName');

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckedItems((prev) => {
      const newCheckedItems = { ...prev };
      if (checked) {
        newCheckedItems[id] = newCheckedItems[id] || 1;
      } else {
        delete newCheckedItems[id];
      }
      return newCheckedItems;
    });
  };

  const handleQuantityChange = (event, productId) => {
    const { value } = event.target;
    setCheckedItems((prev) => {
      const newCheckedItems = { ...prev };
      newCheckedItems[productId] = value;
      return newCheckedItems;
    });
  };

  const handleSubmit = () => {
    const checkedProducts = Object.keys(checkedItems)
      .map((id) => {
        const product = products.find((product) => product.id.toString() === id);
        return `${product.name[language]} (x${checkedItems[id]})`;
      })
      .join('\n ');

    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      `Checked items: ${checkedProducts}`
    )}`;

    window.open(whatsappUrl, '_blank');
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const filteredProducts = products.filter((product) =>
    product.name[language].toLowerCase().includes(searchQuery.toLowerCase())
  );

  const displayedProducts = filteredProducts.slice(
    (currentPage - 1) * PRODUCTS_PER_PAGE,
    currentPage * PRODUCTS_PER_PAGE
  );

  const totalPages = Math.ceil(filteredProducts.length / PRODUCTS_PER_PAGE);

  return (
    <div className="container">
      <div className='emptyContainer'>
        Grocery Item Check List!
      </div>
      <h1>Product List</h1>
      <div className="controls">
        <input
          type="text"
          placeholder="Search products..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
        />
        <select value={language} onChange={handleLanguageChange} className="language-select">
          <option value="englishName">English</option>
          <option value="tamilName">Tamil</option>
          {/* Add more language options here */}
        </select>
      </div>
      <form>
        {displayedProducts.map((product) => (
          <div key={product.id} className="product">
            <label>
              <input
                type="checkbox"
                id={product.id.toString()}
                onChange={handleCheckboxChange}
              />
              {product.name[language]}
            </label>
            
              <input
                type="number"
                min="1"
                value={checkedItems[product.id.toString()]}
                onChange={(e) => handleQuantityChange(e, product.id.toString())}
              />
          </div>
        ))}
        <button type="button" onClick={handleSubmit}>
          Send to WhatsApp
        </button>
      </form>
      <div className="pagination">
        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>
      
      <center><br/>Powerted and Developerd by<br/>Team AIOTI<br/>
      <a href="mailto:capkarthickeyan@gmail.com?subject=Feedback%20or%20Inquiry%20On%20grocery%20CheckList">Feedback/Reach us on</a>

        </center>
    </div>
  );
};

export default App;
